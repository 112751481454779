import { observable, runInAction } from 'mobx';
import { MainSubStructureModel, NormalProgramme, RenderByPermission, request } from '../../utils';
import type { BaseData } from '../../utils';
import { message, Modal, Space, Switch } from 'antd';
import moment from 'dayjs';
import React from 'react';
import AddModel from './components/add/model';

export default class AuthorityManagementModel {
  constructor() {
    this.mainSubStructureModel?.gridModel?.onQuery();
  }

  public addModel: AddModel = new AddModel(this);

  public normalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'roleName',
        label: '角色名称',
      },
      // {
      //   type: 'dateRange',
      //   field: 'startTime',
      //   label: '创建时间',
      // },
    ],
    handleSearch: () => this.mainSubStructureModel.onQuery(),
  });

  public onEnable = async (checked, resourceId) => {
    try {
      this.mainSubStructureModel.gridModel.loading = true;
      await request({
        method: 'POST',
        url: checked ? '/resource/role/enable' : '/resource/role/disable',
        data: { ids: resourceId },
      });
      message.success('操作成功');
    } catch (e) {
      console.error(e);
    } finally {
      this.mainSubStructureModel.gridModel.loading = false;
    }
  };

  public onDelete = (ids: string) => {
    Modal.confirm({
      title: '提示',
      content: '确认删除该角色？',
      onOk: () =>
        request({
          method: 'POST',
          url: `/resource/role/delete`,
          data: { ids },
        }).then(() => {
          message.success('删除成功');
          this.mainSubStructureModel.gridModel.onQuery();
        }),
    });
  };

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增',
        permissionId: '2',
        handleClick: () => {
          runInAction(() => {
            this.addModel.visible = true;
          });
        },
      },
      {
        text: '删除',
        permissionId: '4',
        handleClick: () => {
          const ids = Array.from(this.mainSubStructureModel.gridModel.selectedIds);
          if (!ids.length) {
            message.warning('请选择需要删除的角色！');
            return;
          }
          this.onDelete(ids.join(','));
        },
      },
    ],
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),

    grid: {
      columns: [
        {
          key: 'billingOrderNo',
          name: '操作',
          width: 200,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');

            return (
              <Space>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <a
                    onClick={async () => {
                      runInAction(() => {
                        this.addModel.visible = true;
                        this.addModel.id = row.roleId;
                      });
                      const res = await request<any>({
                        method: 'POST',
                        url: '/resource/role/get',
                        params: { id: row.roleId },
                      });
                      const { remark, roleName, rrp } = res.data || {};
                      this.addModel.formRef.current.setFieldsValue({
                        remark,
                        roleName,
                      });
                      runInAction(() => {
                        const newRpm = {};
                        Object.keys(rrp)?.forEach((key) => {
                          newRpm[key] = rrp[key] ? rrp[key].split(',') : undefined;
                        });
                        this.addModel.rpm = newRpm;
                      });
                    }}
                  >
                    编辑
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <a
                    onClick={() => {
                      this.onDelete(`${row.roleId}`);
                    }}
                  >
                    删除
                  </a>
                </RenderByPermission>
              </Space>
            );
          },
        },
        {
          key: 'roleId',
          name: '角色编号',
        },
        {
          key: 'roleName',
          name: '角色',
        },
        // {
        //   key: 'userCount',
        //   name: '创建时间',
        //   formatter: ({ row }) => <div>{row.userCount}个</div>,
        // },
        {
          key: 'priceList',
          name: '状态',
          formatter: ({ row }) => (
            <Switch
              onChange={(checked) => {
                row.enabled = Number(checked);
                this.onEnable(checked, row.roleId);
              }}
              checked={Boolean(row.enabled)}
            />
          ),
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'roleId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const postParams = {
          ...params,
          filterParams: undefined,
        };
        const searchParams = new URLSearchParams(window.location.search);
        const filterParams = this.normalProgramme.filterItems.params;
        if (filterParams?.startTime) {
          const [startTime, endTime] = filterParams.startTime.split(',');
          filterParams.startTime = moment(startTime).format();
          filterParams.endTime = moment(endTime).format();
        }

        return request<BaseData<any>>({
          url: '/resource/role/page',
          method: 'POST',
          data: {
            ...postParams,
            ...filterParams,
            sysType: searchParams.get('sysType'),
          },
        });
      },
    },
  });
}
